@import 'loadNotoFont'

body
  color: #373C38
  font-family: 'Noto Sans Japanese', 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif !important
  font-weight: 300

$green: #009544

h1, h2, h3
  text-align: center
  width: 100%
  font-weight: 500
  line-height: 1.3em

h1
  font-size: 24px
h2
  font-size: 22px
h3
  font-size: 20px
p
  font-size: 16px
  font-weight: 200
  line-height: 2em
