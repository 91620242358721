@import './mixin'

.plansInfo
  margin-top: 20px
  text-align: center

.paymentPattern
  display: grid
  grid-template-columns: auto 1fr auto 1fr auto 1fr auto
  gap: 20px 8px
  margin-top: 32px
  +max-SP
    grid-template-columns: 1fr auto
    gap: 16px 0

  .paymentPatternName
    font-weight: bold
    width: 100%
    +max-SP
      grid-column: 1 / 3
      margin: 16px 0

  .paymentPatternPlan
    text-align: right
    +max-SP
      text-align: left
      padding-bottom: 8px
      border-bottom: 1px solid #dddddd

  .paymentPatternPay
    text-align: right
    margin-right: 8px
    &.isLast
      margin: 0

    +max-SP
      margin: 0
      padding-bottom: 8px
      border-bottom: 1px solid #dddddd
