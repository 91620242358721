@import './reset'

@import '@fullcalendar/timegrid/main.css'
@import './mixin'
@import './base'
@import './components'
@import './fullCalendar'

#jump
  z-index: 11
  transform: rotate(-45deg)
  -webkit-transform: rotate(-45deg)
  -moz-transform: rotate(-45deg)
  -o-transform: rotate(-45deg)
  transition: 250ms
  width: 200px
  height: 200px
  background-color: #009544
  display: block
  position: fixed
  right: -100px
  bottom: -100px
  mix-blend-mode: multiply
  cursor: auto
  opacity: 0
  +max-SP
    opacity: 0
  .toTop
    position: relative
    display: block
    width: 100%
    height: 100%
    margin: 0 auto
    a
      text-decoration: none
      display: block
      color: white
      font-weight: bold
      text-align: center
      line-height: 80px
      width: 100%
      height: 100%
  &:hover
    mix-blend-mode: normal
    background-color: #fff
    border: solid 2px #009544
    box-sizing: border-box
    cursor: none
    a
      color: #009544

#topview
  width: 100vw
  height: 99vh
  display: flex
  +center
  justify-content: center
  .logo
    height: 40vh
  p
    margin-top: 50px
  a
    margin-top: 50px
    div
      position: relative
      width: 24px
      height: 24px
      margin-bottom: -28px
      border-left: 2px solid $green
      border-bottom: 2px solid $green
      -webkit-transform: rotate(-45deg)
      transform: rotate(-45deg)
      -webkit-animation: displayArrow 2s infinite
      animation: displayArrow 2s infinite
      opacity: 0
      box-sizing: border-box
      @for $i from 1 through 3
        &:nth-of-type(#{$i})
          top: 16px * ( $i - 1 )
          -webkit-animation-delay: .25s * ( $i - 1 )
          animation-delay: .25s * ( $i - 1 )
  @-webkit-keyframes displayArrow
    0%
      opacity: 0
    50%
      opacity: 1
    100%
      opacity: 0
  @keyframes displayArrow
    0%
      opacity: 0
    50%
      opacity: 1
    100%
      opacity: 0

#scrollDown
  width: 100vw
  display: flex
  flex-flow: column
  .line
    width: 100vw
    margin-top: 5px
    background-color: $green
    @for $i from 1 through 4
      &:nth-child(#{$i})
        height: 10px * $i

#first
  margin-top: 5px
  display: flex
  align-items: center
  +max-SP
    flex-direction: column
  h2
    margin-top: 30px

#news
  padding: 0 30px
  margin-bottom: 40px
  text-align: center
  .announcement
    display: inline-block
    margin: 20px auto 30px auto
    padding: 10px 20px
    border: 1px solid $green
    &:last-child
      margin-bottom: 20px
    .strong
      font-weight: bold
    .change
      display: inline-flex
      align-items: center
      margin: 20px auto 0
      +max-SP
        flex-direction: column
      .change__header
        color: $green
      .change__content
        font-size: 1.2em
      .change__arrow
        color: $green
        margin: 1em
        +max-SP
          transform: rotate(90deg)
      .change__after
        font-size: 1.1em

#event
  margin-top: 0
  padding: 30px
  +center
  h2
    margin-bottom: 30px
  +max-SP
    padding: 25px
    margin-top: 0px
  .list
    max-width: 1080px
    display: grid
    grid-template-columns: 1fr 1fr 1fr
    grid-gap: 50px
    +max-SP
      display: block
    .post
      +center
      align-items: flex-start
      +max-SP
        display: block
        margin-bottom: 30px
      img, a
        width: 100%
      h3
        margin-top: 15px
        text-align: left
      p
        text-align: justify
        line-height: 1.5em
        font-weight: 100
      .eventDate
        margin-top: 10px
      .eventDesc
        margin-top: 5px
      .eventStatus
        color: #fff
        width: 100%
        box-sizing: border-box
        font-size: .9em
        font-weight: bolder
        padding-left: 10px
        margin: 5px 0
        line-height: 1.75em
      .eventStatus-end
        background-color: #999
      .eventStatus-today
        background-color: #cc3f3f
      .eventStatus-tommorow
        background-color: #e57104
      .eventStatus-yet
        background-color: #ba7a1a
      a
        $listMaxWidth: 1080px
        $listGridGap: 50px
        display: block
        overflow-y: hidden
        height: calc(((100vw - 100px - #{$listGridGap} * 2) / 3) * (9 / 16))
        max-height: (($listMaxWidth - $listGridGap * 2) / 3 ) * (9 / 16)
        position: relative
        background-color: darken($green, 10%)
        +max-SP
          height: calc((100vw * (9 / 16)))
          max-height: 600px
        transition: .2s
        &::after, &::before // for hover animation
          position: absolute
          content: ''
          display: block
          background-color: #000
          z-index: 20
          transition: .3s
          height: 25px
          opacity: .6
          width: 0
        &::after // left up
          top: 0
          right: 0
        &::before // right down
          left: 0
          bottom: 0
        &:hover
          img
            filter: brightness(115%)
          &::after, &::before
            width: 100%
          &::after
            left: 0
            right: auto
          &::before
            left: auto
            right: 0
        img
          margin: auto 0
          position: absolute
          top: 0
          bottom: 0
          transition: .4s
          filter: brightness(100%)
          z-index: 10
        .loading
          z-index: 0
          opacity: .9
          margin: auto
          width: 5vw
          right: 0
          left: 0
          animation: rotation 1s infinite linear
          @keyframes rotation
            0%
              transform: rotate(0deg)
            100%
              transform: rotate(360deg)
  .error
    img
      display: none
    p
      white-space: pre-wrap
      text-align: center
      line-height: 1.5em
#about
  h2
    margin: 0 30px 30px
  +center
  padding: 0 50px
  +max-SP
    padding: 0 25px
  .content
    +max-SP
      flex-direction: column
      align-items: center
    .text
      margin: 0px auto 20px auto
      max-width: 700px
      line-height: 2em
      +max-SP
        font-size: 19px
        margin: 5px 0
      h3
        margin-bottom: 10px
      p
        text-align: justify
    .room
      max-width: 1080px
      margin-top: 40px
      +max-SP
        display: block
      .item
        margin-bottom: 30px
        display: grid
        grid-template-columns: 1fr 1fr
        grid-template-rows: auto auto 1fr
        +max-SP
          display: block
          margin-bottom: 40px
        grid-gap: 0 1em
        img
          width: 100%
          height: 250px
          object-fit: cover
          grid-row: 1 / 4
        h3
          text-align: left
          +max-SP
            margin-top: 10px
        p
          text-align: justify
          line-height: 1.5em
          margin-top: 10px
#service
  padding: 50px
  +max-SP
    padding: 25px
  +center
  h2
    margin-bottom: 30px
    line-height: 1.9em
  .list
    max-width: 1080px
    margin-top: 30px
    display: grid
    grid-template-columns: 1fr 1fr 1fr 1fr
    grid-gap: 50px
    +max-SP
      grid-template-columns: 1fr
      grid-gap: 10vw 8%
    .item
      +center
      align-items: flex-start
      img
        width: 100%
        // border-radius: 25%//丸くしてるのこれ　消せば四角
      h3
        margin-top: 25px
        text-align: left
      p
        margin-top: 10px
        text-align: justify
        line-height: 1.5em
        font-weight: 100
      +max-SP
        display: grid
        grid-template-rows: 30px 1fr
        grid-template-columns: 100px 1fr
        grid-gap: 0 10px
        img
          grid-row: 1 / 3
          grid-column: 1
        h3
          grid-row: 1 / 2
          grid-column: 2 / 3
          margin-top: 0px
        p
          grid-row: 2 / 3
          grid-column: 2 / 3
          margin-top: 0px

#price
  h2
    margin: 50px auto 30px
    line-height: 1.5em
  .list
    display: flex
    +max-SP
      flex-direction: column
      align-items: center
    justify-content: center
    .item
      width: 300px
      margin: 20px 50px
      &.--wide
        width: 700px
        $margin-mf: 35px
        +max-SP
          width: calc(100% - 50px)
        margin: 40px 20px auto
        .list
          justify-content: space-between
          margin: 20px (-$margin-mf / 2)
        .item
          width: (700px - $margin-mf)/3
          margin: 0 $margin-mf/2 auto
          +max-SP
            width: calc(100% - 50px)
        .info
          margin-top: 20px
          text-align: center
          +max-SP
          text-align: left
      h3
        display: block
        padding: .5em
        border: 1px solid $green
        line-height: 2em
        font-weight: 500
        box-sizing: border-box
      .grid
        margin-top: 10px
        display: grid
        grid-template-columns: auto auto
        grid-gap: .75em
        .plan
          text-align: left
        .pay
          text-align: right
        .taxNotion
          font-size: 0.8em
        .no-grid
          grid-column: 1 / 3
          &.plan
            line-height: 1em
        .annotation
          font-size: 0.9em
          grid-column: 1 / 3
        h4
          grid-column: 1 / 3
          font-weight: bold
          width: 100%
          margin-top: 10px
      .wideItem
        margin-top: 2em
        h4
          grid-column: 1 / 3
          font-weight: bold
          width: 100%
          margin-top: 10px
        .price
          margin-top: 0.75em
          display: flex
          justify-content: space-between
          line-height: 1.5
          .taxNotion
            font-size: 0.8em
        .can
          margin-top: 0.75em
          display: flex
          line-height: 1.5
          align-items: center
          &:before
            content: ''
            background: url('data:image/svg+xml;utf8, <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-circle" class="svg-inline--fa fa-check-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="%23009544" d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path></svg>')
            height: 1em
            width: 1em
            flex-shrink: 0
            margin-right: 0.5em
#reserved
  +center
  h2
    margin-bottom: 20px
  p
    max-width: 700px
    width: 80%
    justify-content: center
    text-align: justify
    margin: 10px 0 0 0
    line-height: 1.5em
  +max-SP
    width: calc(100% - 50px)
    margin: 10px auto 0

#calendar
  text-align: center
  margin: 100px auto
  .gcal
    margin: 30px auto 0
    padding: 0 20px
    max-width: 1080px
    height: 500px

#map
  margin-top: 100px
  .map
    margin-top: 30px
    width: 100vw

#contact
  a
    transition: .25s
    &:link
      color: $green
    &:visited
      color: $green
    &:hover
      color: lighten($green, 10%)
    text-decoration: none
  margin-top: 100px
  .informations
    display: flex
    justify-content: space-between
    width: 70%
    margin: 0 auto

    +max-SP
      display: block
      width: calc(100% - 50px)
    .list
      width: 45%
      +max-SP
        width: 100%
      margin: 20px 0 20px 0px
      .item
        display: grid
        width: 100%
        grid-template-columns: 40px 1fr
        grid-gap: 10px
        margin-bottom: 10px
        p
          display: block
          line-height: 50px
        a
          line-height: 50px
        .mail
          width: 35px
          height: auto
          margin: auto
        .phone
          width: auto
          height: 45px
          margin: auto
        .twitter
          width: 40px
          height: auto
          margin: auto
        .facebook
          width: 40px
          height: auto
          margin: auto
    .announce
      margin: 20px 0
      width: 45%
      line-height: 2em
      +max-SP
        width: 100%
        margin-top: 40px
      .open
        margin: 10px 0
      .close
        margin: 10px 0
      .exception
        p
          text-align: justify
        a
          transition: .25s
          &:link
            color: $green
          &:visited
            color: $green
          &:hover
            color: lighten($green, 10%)
          text-decoration: none

footer
  display: flex
  flex-flow: column
  justify-content: space-between
  padding: 0 50px 50px 50px
  img
    width: 30vw
    +max-SP
      width: 96%
    margin: 50px 0
  p
    line-height: 1.8em
    font-weight: 100
    a
      color: #fff
  .mtw
    margin-top: 1.5em

.tag
  display: inline-block
  height: 1.2em
  vertical-align: sub
  margin-left: .3em
