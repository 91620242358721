=max-PC
  @media screen and (max-width: 1080px - 1px)
    @content

=max-SP
  @media screen and (max-width: 768px - 1px)
    @content

=center
  display: flex
  flex-flow: column
  align-items: center
